import React, { Suspense, useEffect } from "react";
import {Route, Switch, useLocation, withRouter} from "react-router-dom";
import * as Layout from "@42maru/web-starter";
import Preloader from "./component/Preloader";
import preloaderStore from "./store/PreloaderStore";
import { observer } from "mobx-react-lite";
import Dialog from "./component/Dialog";
import Notification from "./component/Notification";
import ReactGA from "react-ga";
import WebChatApp from "./WebChatApp";
import qs from "query-string";
import { configure } from "mobx";
import {
    DISABLE_GA,
    GA_TRACKING_KEY,
} from "./variables";
import * as views from "./view";
import {AuthProvider} from "./view/AuthProvider";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./component/error/ErrorFallback";

configure({
    useProxies: "never"
});

if (!DISABLE_GA) {
    GA_TRACKING_KEY && ReactGA.initialize(GA_TRACKING_KEY);
}

const App: React.FC = observer(() => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    if (/^(\/webchat\/[0-9]*)/.test(location.pathname)) {
        const scenarioId = location.pathname.replace("/webchat/", "");
        const queryStringParams = qs.parse(location.search);
        return (
            <WebChatApp scenarioId={scenarioId || "error"} params={queryStringParams}
                        conversationId={queryStringParams.conversationId}/>
        );
    }

    return (
        <AuthProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Layout.Wrapper>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            <Route key={1} path="/scenarios" exact={true} component={views.Scenarios}/>
                            <Route key={2} path="/scenarios/:scenarioId" component={views.ScenarioContextView}/>
                            <Route key={3} path="*" component={views.NotFound}/>
                        </Switch>
                    </Suspense>
                    <Preloader loading={preloaderStore.loading}/>
                    <Dialog/>
                    <Notification/>
                </Layout.Wrapper>
            </ErrorBoundary>
        </AuthProvider>
    );
});

export default withRouter(App);
