import styled from "styled-components";
import errorBgUrl from "../../static/images/err-bg.svg";
import React from "react";
import ReactDOM from "react-dom";
import {Button, Card, Container} from "react-bootstrap";

interface View5xxProps {
    code: string;
    title: string;
    description: string;
}

const View5xx: React.FC<View5xxProps> = (props) => {
    const onClickReturnHome = () => {
        window.location.href = '/';
    };

    return ReactDOM.createPortal((
        <ErrorViewWrapper>
            <Container className="d-flex justify-content-center align-items-center vh-100">
                <Card className="text-center" style={{maxWidth: "450px", maxHeight: "460px"}}>
                    <Card.Header className="bg-primary text-white py-4">
                        <h3 className="m-0 font-24 text-white">ConversationMaker</h3>
                    </Card.Header>

                    <Card.Body>
                        <h1 className="error-code">{props.code}</h1>
                        <h4 className="text-uppercase mt-3 font-14 text-danger">{props.title}</h4>
                        <p className="mt-3 white-space-pre-wrap font-14 text-secondary">{props.description}</p>
                        <Button variant="primary" onClick={onClickReturnHome}>
                            <i className="mdi mdi-reply me-1"/>
                            Return Home
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        </ErrorViewWrapper>
    ), document.getElementById('portal')!);
};

const ErrorViewWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    background-color: white;
    background-image: url(${errorBgUrl});
    background-size: cover;
    background-position: center;

    .error-code {
        color: #030678;
        text-shadow: rgba(3, 6, 120, 0.3) 5px 1px, rgba(50, 80, 200, 0.2) 10px 3px;
        font-size: 5.25rem;
        line-height: 5.625rem;
    }
`;


export default View5xx;