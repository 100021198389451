import { action, computed, makeObservable, observable, runInAction } from "mobx";
import * as accountApi from "../api/accountApi";
import { Account } from "../model/Account";
import { configure } from "mobx";

configure({
    useProxies: "never"
});

class AuthStore {
    @observable initialized: boolean = false;
    @observable loginUser: Account | undefined = undefined;

    constructor() {
        makeObservable(this);
    }

    @computed
    get isLogin() {
        return (this.loginUser && this.loginUser.email && true) || false;
    }

    @action
    async getLoginUser() {
        try {
            const response = await accountApi.getLoginUser();
            runInAction(() => {
                this.loginUser = response.data;
                this.initialized = true;
            });
            return this.loginUser;
        } catch (e) {
            runInAction(() => {
                this.initialized = true;
            });
            return await Promise.reject(e);
        }
    }

    @action logout = async () => {
        try {
            await accountApi.logout();
        } catch (e) {
            return await Promise.reject(e);
        }
    };
}

const store = new AuthStore();

export default store;
