import React, {useMemo} from "react";
import {AxiosError} from "axios";
import {Chat42Error} from "../../core/Error";
import View4xx from "./View4xx";
import View5xx from "./View5xx";
import {useIntl} from "react-intl";


interface ErrorFallbackProps {
    error: Error;
    resetErrorBoundary: (...args: Array<unknown>) => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    const intl = useIntl();

    const axiosError = useMemo(() => {
        if ('isAxiosError' in error) {
            const axiosError = error as AxiosError
            if (axiosError.isAxiosError) {
                return axiosError;
            }
        }

        return null;
    }, [error]);

    const chat42Error = useMemo(() => {
        if ('isChat42Error' in error) {
            const _chat42Error = error as Chat42Error;
            if (_chat42Error.isChat42Error) {
                return _chat42Error;
            }
        }

        return null;
    }, [error]);

    const errorCode = useMemo(() => {
        if (axiosError)
            return axiosError.response?.statusText;
        else if (chat42Error)
            return chat42Error.code;
    }, [axiosError, chat42Error]);

    if (errorCode === "403")
        return (
            <View4xx code="403" title="Forbidden" description={intl.formatMessage({id: "i0565"})}/>
        );
    else
        return (
            <View5xx code="500" title="" description={intl.formatMessage({id: "i0001"})}/>
        );
}

export default ErrorFallback;