import React, {createContext, useContext, useEffect, useState} from "react";
import authStore from "../store/AuthStore";
import {API_HOST} from "../variables";
import { Account } from "../model/Account";


const AuthContext = createContext<Account|undefined>(undefined);

export const AuthProvider: React.FC = ({children}) => {
    const [loginUser, setLoginUser] = useState<Account|undefined>(undefined);

    useEffect(() => {
        authStore.getLoginUser().then(() => {
            if (authStore.loginUser && authStore.loginUser.user_id && authStore.loginUser.user_id.endsWith("@client"))
                window.location.href = `${API_HOST}/auth/login?client_redirect_uri=${document.URL}`;
            else if (authStore.loginUser)
                setLoginUser(authStore.loginUser);
            else
                window.location.href = `${API_HOST}/auth/login?client_redirect_uri=${document.URL}`;
        }).catch((e) => {
            if ((e.response && e.response.status === 401) || !e.response)
                window.location.href = `${API_HOST}/auth/login?client_redirect_uri=${document.URL}`;
            else
                console.error(e);
        });
    }, [])

    if (!authStore.initialized)
        return null;

    return (
        <AuthContext.Provider value={loginUser}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);