export const messages: Record<string, string> = {
    i0001: "오류가 발생했습니다.",
    i0002: "취소",
    i0003: "확인",
    i0004: "대시보드",
    i0005: "시나리오 편집",
    i0006: "시나리오 흐름도",
    i0007: "시나리오 템플릿",
    i0008: "대화 이력",
    i0009: "챗봇 연동 설정",
    i0010: "시나리오 설정",
    i0011: "시나리오 이름은 필수 입력값입니다.",
    i0012: "시나리오 이름을 50자 이하로 입력하세요.",
    i0013: "웰컴 씬 ID 는 필수 입력값입니다.",
    i0014: "씬 ID 는 숫자값입니다.",
    i0015: "폴백 씬 ID 는 필수 입력값입니다.",
    i0016: "지연시간은 숫자값입니다.",
    i0017: "씬 정보 조회 중 오류가 발생했습니다.",
    i0018: "시나리오 정보 조회 중 오류가 발생했습니다.",
    i0019: "NLU Agent 조회 중 오류가 발생했습니다.",
    i0020: "시나리오 언어를 선택하세요.",
    i0021: "전 단계로 이동",
    i0022: "웰컴 메시지로 이동",
    i0023: "에러가 발생했습니다.",
    i0024: "시나리오 정보가 수정되었습니다.",
    i0025: "시나리오 등록 중 오류가 발생했습니다.",
    i0026: "잘못된 입력값이 있습니다.",
    i0027: "시나리오 삭제 중 오류가 발생했습니다.",
    i0028: "기본정보",
    i0029: "이름",
    i0030: "시나리오 이름을 입력하세요.",
    i0031: "설명",
    i0032: "시나리오 설명을 입력하세요.",
    i0033: "웰컴 씬 ID",
    i0034: "일치하는 씬이 없습니다.",
    i0035: "웰컴 씬 ID를 입력하세요.",
    i0036: "폴백 씬 ID",
    i0037: "폴백 씬 ID를 입력하세요.",
    i0038: "초",
    i0039: "패러그래프 API 지연시간을 설정하세요.",
    i0040: "시나리오 NLU Agent를 선택하세요.",
    i0041: "선택 안 함",
    i0042: "자동완성",
    i0043: "자동완성에 사용할 설정을 선택하세요.",
    i0044: "오류 메시지",
    i0045: "기본 오류 메시지를 입력하세요.",
    i0046: "스택 기능 사용",
    i0047: "이전 단계로 이동 버튼",
    i0048: "전 단계로 가기",
    i0049: "처음으로 이동 버튼",
    i0050: "처음으로 가기",
    i0051: "디버그 메시지 보기",
    i0052: "언어",
    i0053: "한국어",
    i0054: "Google Analytics 사용",
    i0055: "저장",
    i0056: "시나리오 상수 설정",
    i0057: "키",
    i0058: "값",
    i0059: "API KEY 관리",
    i0060: "보기",
    i0061: "복사",
    i0062: "재발급",
    i0063: "시나리오 삭제",
    i0064: "시나리오를 삭제하면 되돌릴 수 없습니다. 신중히 생각하고 결정하세요.",
    i0065: "삭제 후에는 데이터를 되돌릴 수 없습니다.",
    i0066: "닫기",
    i0067: "삭제",
    i0068: "조회 중 오류가 발생했습니다.",
    i0069: "씬 등록",
    i0070: "잘못된 형식입니다.",
    i0071: "수정 중 오류가 발생했습니다",
    i0072: "추가",
    i0073: "기본정보",
    i0074: "이름을 입력하세요.",
    i0075: "템플릿",
    i0076: "유형",
    i0077: "유형을 선택하세요.",
    i0078: "데이터 유형",
    i0079: "데이터 유형을 선택하세요.",
    i0080: "설정",
    i0081: "이름은 필수 입력값입니다.",
    i0082: "이름을 50자 이하로 입력하세요.",
    i0083: "등록 중 오류가 발생했습니다.",
    i0084: "{name}을 삭제하시겠습니까?\n삭제 후에는 데이터를 되돌릴 수 없습니다.",
    i0085: "삭제 중 오류가 발생했습니다.",
    i0086: "유형은 필수 입력값입니다.",
    i0087: "데이터 유형은 필수 입력값입니다.",
    i0088: "데이터는 필수 입력값입니다.",
    i0089: "유효 사용자",
    i0090: "유효 대화",
    i0091: "평균 대화 시간",
    i0092: "전체 메시지",
    i0093: "사용자 메시지",
    i0094: "봇 메시지",
    i0095: "만족",
    i0096: "불만족",
    i0097: "초기화",
    i0098: "사용자",
    i0099: "대화",
    i0100: "일별 유효 사용량",
    i0101: "일별 메시지",
    i0102: "메시지 피드백",
    i0103: "사용자 대화 흐름",
    i0104: "데이터가 없습니다.",
    i0105: "시나리오 목록 조회 중 오류가 발생했습니다.",
    i0106: "시나리오 만들기",
    i0107: "시나리오 목록",
    i0108: "수행 조건",
    i0109: "다음 씬 이동 조건",
    i0110: "Template으로 만들기",
    i0111: "진입 조건의 형식이 잘못되었습니다.",
    i0112: "수행 조건의 형식이 잘못되었습니다.",
    i0113: "드래그하여 이동",
    i0114: "아래로",
    i0115: "위로",
    i0116: "맨 아래로",
    i0117: "맨 위로",
    i0118: "사본 생성",
    i0119: "템플릿으로 만들기",
    i0120: "시나리오 벌크 테스트",
    i0121: "시나리오 Excel 파일 불러오기",
    i0122: "시나리오 Excel 파일 내보내기",
    i0123: "씬 또는 패러그래프의 제목, 설명, 조건, 데이터 등으로 검색하세요.",
    i0124: "WebChat 실행(팝업 윈도우)",
    i0125: "대화 테스트",
    i0126: "씬 ID를 입력하세요.",
    i0127: "진입 조건",
    i0128: "마지막 패러그래프",
    i0129: "시나리오 불러오기",
    i0130: "시나리오 Excel 파일을 여기에 끌어 놓거나, 여기를 클릭하여 파일을 첨부하세요.",
    i0131: "불러오기",
    i0132: "시나리오 벌크 테스트",
    i0133: "테스트 케이스 Excel 파일을 여기에 끌어 놓거나, 여기를 클릭하여 파일을 첨부하세요.",
    i0134: "씬 ID는 필수 입력값입니다.",
    i0135: "씬 ID는 20자 이하로 입력하세요.",
    i0136: "웰컴 씬",
    i0137: "폴백 씬",
    i0138: "웰컴 씬으로 설정",
    i0139: "폴백 씬으로 설정",
    i0140: "이동할 씬 선택",
    i0141: "텍스트 전송",
    i0142: "다음으로 이동",
    i0143: "링크 열기",
    i0144: "버튼명",
    i0145: "동작 선택",
    i0146: "모바일 링크(기본)",
    i0147: "PC 링크",
    i0148: "변수 설정",
    i0149: "메시지 전송 시 함께 보낼 데이터",
    i0150: "버튼 설정",
    i0151: "카드 설정",
    i0152: "타이틀을 입력하세요.",
    i0153: "본문을 입력하세요.",
    i0154: "모바일에서 사용할 Link URL을 입력하세요.",
    i0155: "PC에서 사용할 Link URL을 입력하세요.",
    i0156: "미디어 유형",
    i0157: "선택한 미디어 유형의 URL을 입력하세요.",
    i0158: "조건",
    i0159: "조건 추가하기",
    i0160: "API URL을 입력하세요.",
    i0161: "HTTP Method를 선택하세요.",
    i0162: "헤더 키",
    i0163: "헤더 값",
    i0164: "파라미터 설정",
    i0165: "사용자 입력 메시지",
    i0166: "인텐트 명",
    i0167: "엔티티 명 또는 배열",
    i0168: "0이면 수행 조건에 맞는 패러그래프 없음",
    i0169: "인텐트 연속 중복 횟수",
    i0170: "의도분류 연속 실패 횟수",
    i0171: "변수명 입력 필요",
    i0172: "문자열 값 입력 필요, 숫자인 경우 따옴표 제거",
    i0173: "문자열 배열",
    i0174: "정규식 일치함. source: 정규식, target: 값",
    i0175: "정규식 일치하지 않음. source: 정규식, target: 값",
    i0176: "source가 target에 포함",
    i0177: "source가가 target에 포함되지 않음",
    i0178: "target이 source로 시작함",
    i0179: "target이 source로 끝남",
    i0180: "source가 존재함",
    i0181: "source가 존재하지 않음",
    i0182: "범위를 선택하세요.",
    i0183: "변수의 키 값을 입력하세요.",
    i0184: "변수로 저장할 값을 입력하세요.",
    i0185: "회원가입 중 오류가 발생했습니다.",
    i0186: "이메일은 필수 입력값입니다.",
    i0187: "이메일을 6자 이상 50자 이하로 입력하세요.",
    i0188: "올바른 이메일 형식을 입력하세요.",
    i0189: "이름을 1자 이상 20자 이하로 입력하세요.",
    i0190: "비밀번호는 필수 입력값입니다.",
    i0191: "비밀번호를 6자 이상 12자 이하로 입력하세요.",
    i0192: "로그인 중 오류가 발생했습니다.",
    i0193: "봇",
    i0194: "전체",
    i0195: "메시지 목록 조회 중 오류가 발생했습니다.",
    i0196: "대화 이력 조회 중 오류가 발생했습니다.",
    i0197: "검색할 메시지 내용을 입력하세요.",
    i0198: "채팅창",
    i0199: "Excel 파일 내보내기",
    i0200: "사용자 입력",
    i0201: "챗봇 응답",
    i0202: "입력 시간",
    i0203: "사용자 키",
    i0204: "사용자별",
    i0205: "대화별",
    i0206: "유효",
    i0207: "만족",
    i0208: "불만족",
    i0209: "비밀번호 초기화 중 오류가 발생했습니다.",
    i0210: "이메일은 필수 입력값입니다.",
    i0211: "이메일을 6자 이상 50자 이하로 입력하세요.",
    i0212: "올바른 이메일 형식을 입력하세요.",
    i0213: "챗봇 화면 설정",
    i0214: "시나리오 수정 이력",
    i0215: "시나리오 배포",
    i0216: "버전은 필수 입력값입니다.",
    i0217: "배포",
    i0218: "템플릿 추가",
    i0219: "버전은 필수 입력값입니다.",
    i0220: "설명을 입력하세요.",
    i0221: "버전",
    i0222: "배포",
    i0223: "재배포",
    i0224: "{version} 버전을 배포합니다. 기존 버전으로 서비스 중인 시나리오는 비활성화됩니다.",
    i0225: "시나리오 되돌리기",
    i0226: "시나리오를 변경사항 전으로 되돌립니다.",
    i0227: "되돌리기",
    i0228: "변경사항 보기",
    i0229: "변경사항 없음",
    i0230: "변경 전으로 되돌리기",
    i0231: "시나리오 변경",
    i0232: "시나리오 설정 변경",
    i0233: "시나리오 템플릿 변경",
    i0234: "차이점 보기",
    i0235: "서비스 중",
    i0236: "이미 배포된 시나리오입니다.",
    i0237: "현재 시나리오와 동일합니다.",
    i0238: "변수 설정",
    i0239: "캐러셀 설정",
    i0240: "미디어 설정",
    i0241: "메시지 설정",
    i0242: "메시지가 두 개 이상인 경우 임의의 메시지 노출",
    i0243: "커스텀 데이터 설정",
    i0244: "API 설정",
    i0245: "코드 설정",
    i0246: "시나리오 템플릿 설정",
    i0247: "시나리오 템플릿 목록",
    i0248: "씬 목록",
    i0249: "기본 정렬",
    i0250: "커스텀 ID",
    i0251: "ID",
    i0252: "우선순위",
    i0253: "이름",
    i0254: "오름차순",
    i0255: "내림차순",
    i0256: "씬 설정",
    i0257: "우선순위",
    i0258: "우선순위(숫자)",
    i0259: "패러그래프 설정",
    i0260: "패러그래프 추가",
    i0261: "기타 설정",
    i0262: "시나리오 동작 설정",
    i0263: "변수 초기화 설정",
    i0264: "-1: 초기화하지 않음, 0: 의미 없음, 양의 정수: 숫자만큼 유지",
    i0265: "채팅 상담(개발중)",
    i0266: "PNG로 내려받기",
    i0267: "벌크 테스트 시작",
    i0268: "엑셀파일로 다운받기",
    i0269: "버튼 선택시 전송할 텍스트",
    i0270: "씬 삭제",
    i0271: "씬 추가",
    i0272: "검색",
    i0273: "초기화",
    i0274: "NLU 서비스 선택",
    i0275: "개발중",
    i0276: "버튼",
    i0277: "퀵리플라이",
    i0278: "한국어",
    i0279:
        "WebChat 화면을 설정합니다. 수정 내용은 수정 즉시 반영되며, 챗봇을 사용중인 경우 새로고침해야 변경사항을 확인할 수 있습니다.",
    i0280: "상담 시작",
    i0281: "상담 종료",
    i0282: "메시지를 입력하세요",
    i0283:
        "활성화하면 테스트용 WebChat에서 디버그 메시지를 확인할 수 있습니다.\n- 테스트용 WebChat : 시나리오 편집, 챗봇 화면 설정",
    i0284: "활성화 후 Google Analytics Tracker ID를 입력하면 해당 Tracker ID로 챗봇 이벤트를 전송합니다.",
    i0285: "챗봇이 동작하면서 발생하는 이벤트(대화 메시지 포함)를 설정한 URL로 전송합니다.",
    i0286:
        "시나리오가 동작하는 동안 {{ constant }}와 같이 사용할 수 있는 값을 설정합니다.\n상수명과 동일한 변수를 시나리오 내부에서 사용하는 경우 상수 값은 변수 값으로 대체됩니다.",
    i0287: "설정",
    i0288:
        "Dialogflow > Settings > General > Service Account 클릭 > JSON 키 생성 후 다운받은 JSON 파일의 내용을 복사 & 붙여넣기 해주세요",
    i0289: "챗봇 처음 접속 시 노출할 씬을 설정합니다",
    i0290: "챗봇이 다음 응답 메시지를 찾지 못한 경우 노출할 씬을 설정합니다",
    i0291: "Utility > API 패러그래프 사용 시 API로 부터의 응답 대기 시간을 설정합니다",
    i0292: "챗봇에서 다음 응답을 찾는 도중 오류가 발생했을 때 노출할 메시지를 설정합니다.",
    i0293:
        "챗봇 동작 중 이전 단계로 이동하거나 처음으로 이동하는 기능을 사용하려면 스택 기능을 활성화 합니다.\n이전 단계로 이동 버튼과 처음으로 이동 버튼은 시나리오에서 수동으로 설정해야합니다.",
    i0294: "동일한 템플릿을 여러 씬에서 사용하는 경우 템플릿으로 지정하여 재사용할 수 있습니다.",
    i0295:
        "시나리오에 명시적으로 설정된 다음 이동 조건으로 흐름을 10단계까지 보여줍니다.\n- 배경 클릭 : 전체 연결 보기/숨기기\n- 노드 클릭 : 해당 노드 연결 보기/숨기기\n- 실선 : 버튼으로 연결\n- 점선 : 텍스트 입력 조건으로 연결",
    i0296: "다음 연결 있음",
    i0297: "진입 경로 없음",
    i0298: "다음 연결 없음",
    i0299: "챗봇 사용 통계를 제공합니다.",
    i0300:
        "다음 화면에서 수정 시 운영중인 챗봇에 반영하기 위해 배포가 필요합니다.\n- 시나리오 편집, 시나리오 템플릿, 시나리오 설정",
    i0301: "- deploy: 배포\n- restore: 이전 배포에서 되돌림",
    i0302: "현재 작성되어있는 시나리오와의 차이점을 확인할 수 있습니다.",
    i0303: "운영중인 챗봇을 해당 배포 버전으로 되돌립니다.",
    i0304: "씬을 구분할 수 있는 ID를 설정합니다. ID가 중복되는 경우 챗봇이 의도한대로 동작하지 않을 수 있습니다.",
    i0305:
        "사용자 쿼리에 대한 응답을 찾을 때 진입조건에 매칭되는 씬이 여러개 있는 경우 우선순위가 높은 씬을 답변으로 제공합니다.\n0부터 9999까지의 숫자로 클수록 우선순위가 높습니다.",
    i0306: "챗봇 시나리오를 작성하는 화면입니다. 시나리오 > 씬 > 패러그래프로 구성됩니다.",
    i0307: "서버와 연결이 끊겼습니다",
    i0308:
        "테스트 케이스 엑셀 파일을 업로드하여 여러개의 쿼리를 한번에 테스트 하고 결과를 확인할 수 있습니다.\n- 테스트 케이스 엑셀 파일 : 엑셀 파일(.xlsx), 1열에 사용자 입력 쿼리",
    i0309: "시나리오를 누가 언제 어디서 어떤 내용을 수정했는지 확인할 수 있습니다.(최대 50개)",
    i0310:
        "- scenario_ui: 시나리오 편집 화면\n- scenario_setting_ui: 시나리오 설정 화면\n- paragraph_template_ui: 시나리오 템플릿 화면\n- rollback: 시나리오 롤백\n- publisher_ui: 챗봇 연동 설정 화면",
    i0311: "시나리오 변경 사항을 확인하고 해당 변경사항이 적용되기 이전으로 시나리오를 롤백할 수 있습니다.",
    i0312: "챗봇 사용자의 IP 주소",
    i0313: "챗봇 사용자 키값으로 챗봇 채널마다 형식이 다를 수 있습니다.\n- WebChat: 32자 랜덤 값",
    i0314:
        "챗봇 사용자가 대화를 진행한 채널입니다.\n- WebChat, 카카오톡 외 커스터마이징 된 웹이나 사이트가 될 수 있습니다.",
    i0315: "챗봇이 응답한 횟수입니다. Welcome이 1이고 이후 응답은 2부터 시작합니다",
    i0316: "피드백 수집 기능을 사용하는 경우 해당 챗봇 응답에 대한 피드백을 확인할 수 있습니다.\n- good or bad",
    i0317: "@엔티티 이름|엔티티 값",
    i0318: "시스템에서 관리하는 씬의 ID 입니다.",
    i0319: "시나리오 편집 > 씬에서 작성한 ID 입니다.",
    i0320: "챗봇 응답이 어떤 패러그래프로 구성되어있는지 확인할 수 있습니다.",
    i0321:
        "- 전체 메시지 : 사용자 입력 쿼리와 챗봇 응답\n- 사용자별 : 사용자 기준 대화 이력\n- 대화별 : 대화 기준 대화 이력(한 사용자는 여러개의 대화를 진행할 수 있음)",
    i0322: "파일 첨부",
    i0323: "테스트 케이스의 테스트 갯수",
    i0324: "테스트 수행 시간(분:초)",
    i0325: "테스트 상태\n- progress: 진행중\n- done: 완료",
    i0326: "테스트 케이스 다운로드",
    i0327: "테스트 결과 다운로드",
    i0328: "접기",
    i0329: "펼치기",
    i0330: "모두 접기",
    i0331: "모두 펼치기",
    i0332:
        "- 패러그래프를 선택(Ctrl(Cmd) + Click)하여 여러개의 패러그래프 순서를 변경할 수 있습니다.\n- 마우스 오른쪽 버튼을 누르면 메뉴를 사용할 수 있습니다.",
    i0333: "Output",
    i0334: "Utility",
    i0335: "Template",
    i0336: "Input",
    i0337: "Next",
    i0338: "Text",
    i0339: "Carousel",
    i0340: "Media",
    i0341: "Custom",
    i0342: "List",
    i0343: "Quick Reply",
    i0344: "Text",
    i0345: "Variable",
    i0346: "Python Code",
    i0347: "API",
    i0348: "Next",
    i0349: "Template",
    i0350: "사용자에게 지정한 형식의 메시지를 노출합니다.",
    i0351: "챗봇 동작에 필요한 값을 설정합니다.",
    i0352: "미리 정의한 템플릿을 사용합니다.",
    i0353: "사용자의 입력을 기다립니다.",
    i0354: "다음에 바로 연결되는 씬을 설정합니다.",
    i0355: "텍스트 형식의 메시지입니다.(여러개 설정하는 경우 랜덤으로 하나의 문구가 노출됩니다.)",
    i0356: "카드 형태로 구성되어있는 캐러셀 형식의 메시지 입니다.",
    i0357: "미디어 형식의 메시지 입니다. 이미지, 비디오, 오디오를 설정할 수 있습니다.",
    i0358: "JSON 형식으로 커스터마이징 된 응답 형태로 활용할 수 있습니다.",
    i0359: "리스트 형태로 버튼을 제공합니다.",
    i0360: "칩 형태로 버튼을 제공합니다.",
    i0361: "텍스트 입력을 대기합니다.",
    i0362:
        "변수를 설정할 수 있습니다. 여기서 설정한 key는 {example}와 같이 챗봇 응답이나 조건 등에 사용할 수 있습니다.",
    i0363: "파이썬 코드를 실행 후 결과를 변수로 저장할 수 있습니다.\n코드는 30000자를 넘을 수 없습니다.",
    i0364: "외부 API를 호출하여 결과를 활용할 수 있습니다. {example}",
    i0365: "Next의 데이터 유형은 변경할 수 없습니다",
    i0366: "Template의 데이터 유형은 변경할 수 없습니다",
    i0367: "{target} 템플릿에 선택하신 패러그래프가 추가되었습니다",
    i0368: "템플릿에 추가",
    i0369: "{count}개의 패러그래프",
    i0370: "패러그래프",
    i0371: "템플릿 삭제",
    i0372: "WebChat 연동 설정",
    i0373: "기본 설정",
    i0374: "제목",
    i0375: "제목 텍스트",
    i0376: "제목 이미지 URL (최우선)",
    i0377: "이미지 URL",
    i0378: "메시지 PlaceHolder",
    i0379: "버튼 텍스트 맞춤",
    i0380: "왼쪽",
    i0381: "가운데",
    i0382: "오른쪽",
    i0383: "캐러셀 버튼 텍스트 맞춤",
    i0384: "미디어(이미지, 비디오, 오디오)를 말풍선 밖에 표시",
    i0385: "아바타 노출",
    i0386: "아바타 위치",
    i0387: "위",
    i0388: "아래",
    i0389: "아바타 이미지 URL",
    i0390: "아바타 크기",
    i0391: "아바타 모양 둥글게",
    i0392: "닫기 버튼 노출",
    i0393: "자동완성 기능 사용",
    i0394: "피드백 수집 기능 사용",
    i0395: "피드백 수집 메시지",
    i0396: "답변이 유용했나요?",
    i0397: "메시지 배경",
    i0398: "메시지 글자",
    i0399: "긍정 아이콘",
    i0400: "긍정 아이콘 활성",
    i0401: "텍스트 더보기 사용",
    i0402: "표시할 텍스트 라인 수",
    i0403: "색상 설정",
    i0404: "채팅창 배경",
    i0405: "배경",
    i0406: "글자",
    i0407: "플레이스 홀더",
    i0408: "전송 버튼",
    i0409: "타이틀 글자",
    i0410: "타이틀 배경 시작",
    i0411: "닫기 버튼",
    i0412: "글자",
    i0413: "배경",
    i0414: "글자",
    i0415: "리스트 버튼 글자",
    i0416: "리스트 버튼 마우스 오버",
    i0417: "리스트 버튼 배경",
    i0418: "버튼 글자",
    i0419: "버튼 배경",
    i0420: "버튼 글자",
    i0421: "버튼 배경",
    i0422: "이동 화살표",
    i0423: "글자",
    i0424: "배경",
    i0425: "저장",
    i0426: "미리보기",
    i0427: "웹 사이트 삽입 배너 설정",
    i0428: "버튼 설정",
    i0429: "채팅창 설정",
    i0430: "웹 사이트 삽입 코드",
    i0431: "클립보드로 복사",
    i0432: "언어 설정",
    i0433: "준비중 버튼 글자",
    i0434: "준비중 버튼 배경",
    i0435: "준비중 버튼 글자",
    i0436: "준비중 버튼 배경",
    i0437: "메시지 노출 지연시간(초)",
    i0438: "자동완성 노출 갯수",
    i0439: "설명",
    i0440: "아바타 이름",
    i0441: "타이틀 배경 끝",
    i0442: "버튼 테두리",
    i0443: "준비중 버튼 테두리",
    i0444: "버튼 테두리",
    i0445: "준비중 버튼 테두리",
    i0446: "글자",
    i0447: "배경",
    i0448: "팝업 이동 텍스트",
    i0449: "하이라이트",
    i0450: "부정 아이콘",
    i0451: "부정 아이콘 활성",
    i0452: "버튼 마우스 오버",
    i0453: "테두리",
    i0454: "테두리",
    i0455: "테두리",
    i0456: "리스트 버튼 구분선",
    i0457: "확인 버튼 글자",
    i0458: "확인 버튼 배경",
    i0459: "확인 버튼 테두리",
    i0460: "고급 설정",
    i0461: "기본",
    i0462: "대화 입력창",
    i0463: "시간 표시",
    i0464: "아바타",
    i0465: "자동완성",
    i0466: "전체보기",
    i0467: "피드백 수집",
    i0468: "퀵 리플라이",
    i0469: "봇 말풍선 텍스트 타입",
    i0470: "봇 말풍선 캐러셀 타입",
    i0471: "사용자 말풍선",
    i0472: "파일 첨부",
    i0473: "배너 설정",
    i0474: "배너 텍스트",
    i0475: "배너 글자",
    i0476: "배경 시작",
    i0477: "배경 끝",
    i0478: "채팅창 오픈 시 배경 Dim 처리",
    i0479: "배너 로드 시 채팅창 열기",
    i0480: "배너 미리 보기",
    i0481: "배너 버튼이 다른 UI에 가려지는 경우 z-index 숫자를 높이세요.",
    i0482: "카카오톡 연동",
    i0483: "웰컴 스킬 URL",
    i0484: "폴백 스킬 URL",
    i0485: "카카오톡 오픈빌더 연동 방법",
    i0486: "챗봇 연동 설정 변경",
    i0487: "접속 사용자(실사용자)",
    i0488: "접속 세션(실세션)",
    i0489: "전체 메시지(사용자 메시지)",
    i0490: "세션당 평균 사용자 메시지",
    i0491:
        "사용자가 메시지를 입력 시 이 씬에 진입하도록 하는 조건을 설정합니다.\nand, or (, ), ==, != 등의 조건문을 사용할 수 있습니다.\n? 입력: 다음입력 예시\n# 입력: 인텐트 목록\n@ 입력: 엔티티 목록\n$ 입력: 사용가능한 변수, 상수 목록",
    i0492:
        "이 씬에 진입했을때 패러그래프를 수행하는 조건을 설정합니다.\nand, or (, ), ==, != 등의 조건문을 사용할 수 있습니다.\n? 입력: 다음입력 예시\n# 입력: 인텐트 목록\n@ 입력: 엔티티 목록\n$ 입력: 사용가능한 변수, 상수 목록",
    i0493: "표시할 항목이 없습니다. 시나리오 설정 > NLU 설정 > 42Maru NLU 설정을 확인해주세요",
    i0494: "ID는 유일한 값이어야 합니다",
    i0495: "ID는 필수 입력값입니다",
    i0496: "데이터 유형을 선택해주세요",
    i0497: "변수를 추가해주세요",
    i0498: "변수의 범위를 선택해주세요",
    i0499: "변수의 키를 입력해주세요",
    i0500: "변수의 값을 입력해주세요",
    i0501: "미디어 타입을 선택해주세요",
    i0502: "미디어의 URL을 입력해주세요",
    i0503: "올바른 URL을 입력해주세요",
    i0504: "카드를 추가해주세요",
    i0505: "미디어, 타이틀, 본문 중 한가지는 필수로 입력해야합니다",
    i0506: "버튼을 추가해주세요",
    i0507: "버튼 라벨을 입력해주세요",
    i0508: "버튼의 동작을 선택해주세요",
    i0509: "이동할 씬을 선택해주세요",
    i0510: "모바일 링크는 필수입니다",
    i0511: "이름은 필수 입력값 입니다",
    i0512: "이동할 씬을 선택해주세요",
    i0513: "API URL을 입력해주세요",
    i0514: "HTTP Method를 선택해주세요",
    i0515: "파라미터 키를 입력해주세요",
    i0516: "파라미터 값을 입력해주세요",
    i0517: "헤더 키를 입력해주세요",
    i0518: "헤더 값을 입력해주세요",
    i0519: "다음 씬 이동 조건을 추가해주세요",
    i0520: "조건을 입력해주세요",
    i0521: "이동할 씬을 선택해주세요",
    i0522: "커스텀 데이터는 올바른 JSON 형식이어야 합니다",
    i0523: "텍스트를 입력해주세요",
    i0524: "웹 브라우저에서 동작하는 챗봇을 설정할 수 있습니다.",
    i0525: "카카오톡",
    i0526: "카카오톡 오픈빌더와 연동하여 카카오톡으로 챗봇을 서비스할 수 있습니다.",
    i0527: "카카오톡 연동 활성화",
    i0528: "카카오톡과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 카카오톡에서 챗봇이 동작하지 않습니다.",
    i0529: "시작",
    i0530: "카카오톡 오픈빌더",
    i0531: "카카오톡 오픈빌더에 접속 후 카카오톡 챗봇을 생성합니다.",
    i0532: "스킬 설정",
    i0533:
        "스킬 탭을 선택하여 웰컴 스킬, 폴백 스킬을 추가합니다.\n웰컴 스킬 URL과 폴백 스킬 URL을 복사하여 붙여넣기 하세요.",
    i0534: "블럭 설정",
    i0535: "시나리오 탭을 선택하여 웰컴 블록을 선택 후 웰컴 스킬을 설정합니다.",
    i0536: "웰컴 블록과 마찬가지로 폴백 블록을 선택 후 폴백 스킬을 설정합니다.",
    i0537: "채널 연결",
    i0538:
        "설정 탭을 선택하여 카카오톡 채널을 연결합니다.\n카카오톡 채널은 카카오톡채널 관리자 센터에서 관리하실 수 있습니다.",
    i0539: "카카오톡채널 관리자 센터",
    i0540: "배포",
    i0541: "배포 탭을 선택하신 후 배포를 진행하시면 카카오톡 채널에서 챗봇 동작을 확인하실 수 있습니다.",
    i0542: "변경사항이 있습니다. 변경사항을 취소하고 다른 페이지로 이동할까요?",
    i0543: "시나리오 배포",
    i0544: "시나리오 복원",
    i0545: "시간",
    i0546: "실행한 사람",
    i0547: "구분",
    i0548: "버전",
    i0549: "설명",
    i0550: "차이점",
    i0551: "진행중",
    i0552: "완료",
    i0553: "테스트 케이스 총 개수",
    i0554: "테스트 수행시간(mm:hh)",
    i0555: "상태",
    i0556: "테스트 케이스",
    i0557: "테스트 결과",
    i0558: "시나리오 편집",
    i0559: "시나리오 설정",
    i0560: "시나리오 템플릿",
    i0561: "변경 전으로 되돌리기",
    i0562: "챗봇 연동 설정",
    i0563: "수정 위치",
    i0564: "변경점",
    i0565: "접근 권한이 없습니다. 시스템 관리자에게 문의하세요.",
    i0566: "이동하기",
    i0567: "시나리오 만들기",
    i0568: "소유자",
    i0569: "ID는 200자 이하여야 합니다",
    i0570: "이름은 200자 이하여야 합니다",
    i0571: "템플릿을 선택해주세요",
    i0572: "키 값은 공백이 포함할 수 없습니다",
    i0573: "ID는 공백이 포함될 수 없습니다",
    i0574: "인텐트 선택 기능 사용",
    i0575: "인텐트 사용",
    i0576: "적합 인텐트 기준 스코어",
    i0577: "유사 인텐트 기준 스코어",
    i0578: "적합 인텐트 선택 메시지 설정",
    i0579: "적합 인텐트 선택 버튼 형식",
    i0580: "유사 인텐트 선택 메시지 설정",
    i0581: "유사 인텐트 선택 버튼 형식",
    i0582: "유사 인텐트 확인 메시지 설정",
    i0583: "유사 인텐트 확인 버튼 형식",
    i0584: "이 기능을 활성화하면 인텐트 스코어에 따라 선택지를 제공할 수 있습니다.",
    i0585: "선택지에 제공하지 않을 인텐트(구분: 쉼표(,))",
    i0586: "NLU 결과 커스터마이징(Python)",
    i0587: "Live chat",
    i0588: "상담원 연결을 하기 위한 형식입니다.",
    i0589: "카드 선택 시 동작 설정",
    i0590: "없음",
    i0591: "링크로 이동",
    i0592: "텍스트 전송",
    i0593: "다음으로 이동",
    i0594: "이전으로 돌아가기",
    i0595: "템플릿 이름은 유일한 값이어야 합니다",
    i0596: "해당 템플릿을 사용하는 씬이 있어 삭제할 수 없습니다",
    i0597: "해당 템플릿을 사용하는 템플릿이 있어 삭제할 수 없습니다",
    i0598: "감정",
    i0599: "기쁨",
    i0600: "상처",
    i0601: "슬픔",
    i0602: "불안",
    i0603: "당황",
    i0604: "분노",
    i0605: "아바타 이미지 설정",
    i0606: "팀업",
    i0607: "팀업 메신저와 연동하여 챗봇을 서비스할 수 있습니다",
    i0608: "팀업 연동",
    i0609: "팀업 메신저 연동 활성화",
    i0610: "구축형",
    i0611: "시나리오 이름, 설명, 작성자 검색",
    i0612: "시나리오 업로드",
    i0613: "File",
    i0614: "파일 다운로드 메시지 입니다. 설명, 파일 다운로드 버튼 이름을 설정할 수 있습니다.",
    i0615: "파일 다운로드 데이터 설정",
    i0616: "파일을 선택하거나 파일 URL을 입력하세요",
    i0617: "첨부한 파일에대한 설명을 입력하세요",
    i0618: "파일 다운로드 버튼 라벨을 입력하세요",
    i0619: "메시지에서 HTML 태그 제거(성능 저하)",
    i0620: "LGUplus RCS",
    i0621: "LGUplus Rich Communication Service로 연동하여 SMS로 챗봇을 제공할 수 있습니다",
    i0622: "RCS 연동",
    i0623: "RCS 연동 활성화",
    i0624: "다른 시나리오로 동작",
    i0625: "시나리오 선택",
    i0626: "메신저",
    i0627: "채팅 상담",
    i0628: "스펙트라의 eNomix Engager와 연동하여 채팅상담을 진행할 수 있습니다",
    i0629: "eNomix 연동",
    i0630: "eNomix 연동 활성화",
    i0631: "LiveChat 연동",
    i0632: "LiveChat 연동 활성화",
    i0633: "LiveChat과 연동하여 채팅상담을 진행할 수 있습니다",
    i0634: "Disable Keyboard",
    i0635: "사용자의 키보드 입력을 제한합니다",
    i0636: "시나리오 통계 설정",
    i0637: "문의 유형 통계 제외 씬 설정",
    i0638: "네이버톡톡",
    i0639: "네이버톡톡으로 챗봇을 서비스할 수 있습니다.",
    i0640: "네이버톡톡 연동",
    i0641: "네이버톡톡 연동 활성화",
    i0642: "네이버톡톡과 챗봇을 연동하려면 체크하세요",
    i0643: "이벤트 받을 URL",
    i0644: "페이스북 메신저",
    i0645: "페이스북 페이지에 챗봇을 서비스 할 수 있습니다",
    i0646: "페이스북 연동",
    i0647: "페이스북 연동 활성화",
    i0648: "인스타그램",
    i0649: "인스타그램에서 챗봇을 서비스할 수 있습니다",
    i0650: "인스타그램 연동",
    i0651: "인스타그램 연동 활성화",
    i0652: "팀업 메신저와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 팀업 메신저에서 챗봇이 동작하지 않습니다.",
    i0653: "eNomix와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 eNomix와 상담이 진행되지 않습니다.",
    i0654: "LiveChat과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 LiveChat과 상담이 진행되지 않습니다.",
    i0655: "LGUplus RCS와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 LGUplus RCS에서 챗봇이 동작하지 않습니다.",
    i0656: "UiPath와 챗봇을 연동하려면 체크하세요.",
    i0657: "페이스북과 챗봇을 연동하려면 체크하세요",
    i0658: "인스타그램과 챗봇을 연동하려면 체크하세요",
    i0659: "배너 표시 URL 화이트 리스트(쉼표로 구분)",
    i0660: "올바른 카카오톡 Share URL을 입력하세요. 예) http://pf.kakao.com/xxxxxx (카카오톡 비즈니스 관리자 센터에서 확인하실 수 있습니다. https://business.kakao.com)",
    i0661: "올바른 페이스북 Share URL을 입력하세요. 예) https://m.me/page_name",
    i0662: "올바른 인스타그램 Share URL을 입력하세요. 예) https://www.instagram.com/instagram_account",
    i0663: "올바른 네이버톡톡 Share URL을 입력하세요. 예) http://naver.me/xxxxxx",
    i0664: "네이버톡톡 링크를 가져오려면 QR코드 이미지(PNG)를 여기에 업로드해주세요.\n\n[QR코드 다운로드 방법]\n네이버톡톡 파트너센터 > 설정 > 톡톡 배너/QR코드 다운 > 우측 하단 '다운받기' > PNG로 저장",
    i0665: "라인",
    i0666: "라인 채널에서 챗봇을 서비스할 수 있습니다.",
    i0667: "라인 연동 활성화",
    i0668: "라인 Webhook Url",
    i0669: "라인 연동",
    i0670: "라인 연동 방법",
    i0671: "1. provider를 생성한 뒤 선택합니다.\n2. channel생성을 시작하여 messaging api를 선택합니다.",
    i0672: "채널에 들어가 Messaging API 탭을 선택하고 WebHook Url 주소를 추가합니다.\n라인 Webhook Url을 복사 붙여넣기 하세요.",
    i0673: "Line Official Account features의 Auto-reply messages와 Greeting messages를 Disabled로 바꿔주어야합니다.\nAuto-reply messages의 Edit 버튼을 클릭하여 설정창으로 들어갑니다.",
    i0674: "Channel access token 생성",
    i0675: "Webhook 설정",
    i0676: "채널 답변 특성 설정",
    i0677: "Channel access token 생성해줍니다.",
    i0678: "표시된 것과 같이 설정해줍니다.",
    i0679: "라인과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 라인에서 챗봇이 동작하지 않습니다.",
    i0680: "인덱스 화면 사용",
    i0681: "올바른 라인 Share URL을 입력하세요. 예) https://line.me/R",
    i0682: "Share URL 가이드",
    i0683: "Share URL을 설정하면 WebChat에 메신저로 바로가기 아이콘을 노출할 수 있습니다.",
    i0684: "1. 페이지 관리하기에서 받은 메시지함 클릭",
    i0685: "2. 받은 메시지함에서 톱니바퀴 클릭",
    i0686: "3. Messaging URL 복사 후 Share URL에 등록",
    i0687: "1. 채널 관리자 센터 접속",
    i0688: "Share URL 설정 방법",
    i0689: "2. 채널 홍보 탭 클릭\n3. 채팅 URL 복사",
    i0690: "Share URL 가이드",
    i0691: "네이버톡톡 연동 가이드",
    i0692: "1. 네이버톡톡 파트너센터 접속",
    i0693: "2. 톡톡 계정 생성 후 계정 관리 페이지 접속\n3. 개발자도구 탭의 챗봇API 설정 클릭",
    i0694: "네이버톡톡 연동 설정",
    i0695: "수신 이벤트 설정",
    i0696: "1. Webhook URL 설정\n2. 수신할 이벤트 설정\n3. Authorization 생성 후 CM에 등록",
    i0697: "수신할 이벤트는 end, open, leave, friend, echo 체크",
    i0698: "인스타그램은 채팅으로 바로가기 URL을 제공하지 않습니다.\n인스타그램 계정 URL을 입력해주세요.\n\nex) https://www.instagram.com/계정명",
    i0699: "로그인 사용자 통계",
    i0700: "로그인 사용자 통계에서 추가 노출 항목을 설정할 수 있습니다",
    i0701: "키 값 예) extra_info.nickname",
    i0702: "타이틀명 예) 닉네임",
    i0703: "대화 이력 추가 항목",
    i0704: "대화 이력에 노출되는 추가 항목을 설정할 수 있습니다.",
    i0705: "키 값 예) context.global.login_user.id",
    i0706: "타이틀명 예) 아이디",
    i0707: "대화 이력 다운로드 추가 항목",
    i0708: "Microsoft Azure Bot Services",
    i0709: "Microsoft Azure Bot Services를 통해 추가적인 외부 메신저와 연결할 수 있습니다.\n(Teams, Alexa, Slack, Telegram등)",
    i0711: "Microsoft Azure Bot Services 연동",
    i0712: "Microsoft Azure Bot Services 연동 활성화",
    i0713: "Webhook URL",
    i0714: "올바른 애저 Share URL을 입력하세요. 예) https://teams.microsoft.com/",
    i0715: "애저 연동 가이드",
    i0716: "Azure Bot 생성",
    i0717: "에 접속하여 Bot Services > 만들기 > 맨 아래 '추가 로드' > Azure Bot을 선택하여 생성합니다.",
    i0718: "Webhook URL 등록",
    i0719: "Webhook URL을 복사하여 끝점 메시지 보내기에 입력합니다.",
    i0720: "1. Microsoft App ID를 복사하여 위 Microsoft App ID 항목에 입력합니다.\n2. 관리를 클릭하여 비밀번호 생성창으로 접속합니다.",
    i0721: "3. 새 클라이언트 암호를 클릭하여 비밀번호를 생성하고\n값에 복사하기 버튼을 클릭하여 복사한 뒤 위의 Microsoft Password 항목에 입력합니다.",
    i0722: "팀즈 연동하기",
    i0723: "1. Microsoft Teams 채널을 클릭합니다.",
    i0724: "2. Microsoft Teams 설정 후에 저장을 클릭합니다.(기본값으로 저장해도 무관)",
    i0725: "Microsoft App 정보 등록",
    i0726: "슬랙 연동",
    i0727: "슬랙 연동 활성화",
    i0728: "슬랙과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 슬랙에서 챗봇이 동작하지 않습니다.",
    i0729: "슬랙",
    i0730: "슬랙 연동 가이드",
    i0731: "슬랙 app 생성",
    i0732: "에 접속하여 create new app으로 app을 생성합니다.\n그리고 생성된 app의 설정화면으로 이동합니다.",
    i0733: "상호작용 컴포넌트 웹 훅 등록",
    i0734: "Slack Interactivity Webhook URL을 복사하여 상호작용 컴포넌트의 Request URL에 등록해줍니다.",
    i0735: "Oauth Redirect URL 등록",
    i0736: "Slack Oauth Redirect URL을 복사하여 Redirect URL에 추가해줍니다.",
    i0737: "이벤트 구독",
    i0738: "Bot Token Scope 등록",
    i0739: "Bot Token Scope에 chat:write를 추가해줍니다.\n이를 추가해야 챗봇이 유저에게 메시지를 보낼 수 있습니다.",
    i0740: "이벤트를 수신하기 위해 Slack Event Webhook URL을 복사하여 Request URL에 등록해줍니다.\n이벤트에 app_home_opened, app_mention, message.im, member_joined_channel, app_uninstalled를 등록합니다.",
    i0741: "워크스페이스에 앱 설치",
    i0742: "install 버튼을 클릭하여 워크스페이스에 앱을 설치합니다.",
    i0743: "연동 정보 입력",
    i0744: "install 후 보여지는 bot User OAuth Token을 Conversation Manager의 Bot User OAuth Token에 입력합니다.",
    i0745: "Client ID와 Client Secret 또한 복사하여 Conversation Maker에 입력해줍니다.",
    i0746: "슬랙 워크스페이스에서 챗봇을 서비스할 수 있습니다.",
    i0747: "메시지 탭 활성화",
    i0748: "1. App Home > Your App’s Presence in Slack 에서 Always Show My Bot as Online을 활성화해줍니다.\n2. App Home > Show Tab에서 Message Tab을 활성화해줍니다.\n3. Allow users to send...(이하 생략) 체크 박스를 체크해줍니다.\n이를 통해 앱과 직접 메시지를 주고받을 수 있습니다.",
    i0749: "Zendesk",
    i0750: "Zendesk와 연동하여 채팅상담을 진행할 수 있습니다.",
    i0751: "Zendesk 연동",
    i0752: "Zendesk 연동 활성화",
    i0753: "Zendesk 연동 방법",
    i0754: "Zendesk 웹사이트에 접속하여 채널을 생성합니다.",
    i0755: "API Token 생성",
    i0756: "웹훅 생성하기",
    i0757: "웹훅 생성 페이지에 접속합니다.",
    i0758: "웹훅 만들기 버튼을 클릭한 뒤\n아래의 두 가지 웹훅을 만들어줍니다.\n1.상담사 메시지 웹훅\n2.상담 종료 웹훅",
    i0759: "트리거 만들기",
    i0760: "트리거 생성 페이지에 접속합니다.",
    i0761: "이전 단계에서 생성한 웹훅을 사용하는 두 가지 트리거를 만들어줍니다.\n1.상담사 메시지 트리거\n2.상담 종료 트리거",
    i0762: "웹훅 생성하기",
    i0763: "에 접속하여 api 토큰을 생성하여 conversation maker의 API Token칸에 입력해줍니다.\n",
    i0764: "2. 생성된 채널에 접속하여 subdomain을 확인하고 conversation manager의 subdomain칸에 입력합니다.\n(예시 - https://{{subdomain}}.zendesk.com)",
    i0765: "1. 채널을 생성한 아이디의 이메일을 conversation maeker의 Zendesk Email 칸에 입력합니다.",
    i0766: "상담사 메시지 트리거에는 웹훅의 JSON 본문에 Message Webhook Body를 복사/붙여넣기 해줍니다.",
    i0767: "상담 종료 트리거에는 웹훅의 JSON 본문에 End Webhook Body를 복사/붙여넣기 해줍니다.",
    i0768: "SwingChat Message",
    i0769: "SwingChat Message의 메시지 전송 기능을 사용할 수 있습니다.",
    i0770: "SwingChat Message 연동",
    i0771: "SwingChat Message 연동 활성화",
    i0772: "Zendesk와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 Zendesk에서 챗봇이 동작하지 않습니다.",
    i0773: "SwingChat Message와 챗봇을 연동하려면 체크하세요. 체크를 해제하면 SwingChat Message의 기능을 사용할 수 없습니다.",
    i0774: "상담 종료 트리거 만들기",
    i0775: "업무 시간 외 상담 거절 트리거 만들기",
    i0776: "위 링크에 접속하여 상담 종료 트리거를 생성합니다.",
    i0777: "위 링크에 접속하여 업무 시간 외 상담 거절 트리거를 생성합니다.",
    i0778: "위 링크에 접속하여 업무 시간으로 설정할 시간을 일정으로 생성합니다.",
    i0779: "웹훅의 JSON 본문에 Counseling Reject Body를 복사/붙여넣기 해줍니다.",
    i0780: "FAQ 관리",
    i0781: "카테고리",
    i0782: "카테고리 추가",
    i0783: "카테고리 삭제",
    i0784: "80자 이내로 입력해주세요",
    i0785: "템플릿으로 이동하여 편집",
    i0786: "복사 완료",
    i0787: "Token을 재발급하면 기존에 발급된 토큰을 사용하는 Open API가 정지될 수 있습니다.",
    i0788: "Token을 삭제하면 사용중인 Open API를 사용할 수 없습니다.",
    i0789: "이미 사본이 존재합니다.",
    i0790: "테스트 실패",
    i0791: "시나리오 추가",
    i0792: "시나리오 설정 추가",
    i0793: "시나리오 템플릿 추가",
    i0794: "챗봇 연동 설정 추가",
    i0795: "시나리오 삭제",
    i0796: "시나리오 설정 삭제",
    i0797: "시나리오 템플릿 삭제",
    i0798: "챗봇 연동 설정 삭제",
    i0799: "Body에 합쳐질 callback 요청을 특정할 Webhook ID의 필드명입니다.",
    i0800: "메시지에 변형이 필요한 경우 선택합니다.",
    i0801: "영어",
    i0802: "잔디",
    i0803: "잔디 대화방에서 챗봇을 서비스할 수 있습니다.",
    i0804: "잔디 연동",
    i0805: "잔디 연동 활성화",
    i0806: "잔디과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 잔디에서 챗봇이 동작하지 않습니다.",
    i0807: "잔디 연동 가이드",
    i0808: "Webhook 발신 커넥트 연동",
    i0809: "Webhook 발신 커넥트 클릭합니다.",
    i0810: "연동 정보 입력",
    i0811: "챗봇 시작 키워드를 입력합니다.",
    i0812: "커넥트 토큰을 복사해 jandi 웹훅 토큰에 추가하고 워크 스페이스를 입력합니다.",
    i0813: "Jandi Outgoing Webhook URL을 복사해 커넥트의 URL에 입력합니다",
    i0814: "챗봇 사용",
    i0815: "잔디 커넥트가 있는 상태에서 시작 키워드를 입력하면 챗봇을 사용할 수 있습니다",
    i0816: "연동 커넥트 리스트",
    i0817: "커넥트 이름",
    i0818: "Jandi 웹훅 토큰",
    i0819: "잔디 대화방 우측 상단의 커넥트 버튼과 연동 추가 버튼 클릭합니다.",
    i0820: "(선택) 명령어 힌트와 커넥트에 대한 설명 입력합니다.",
    i0821: "잔디 커넥트를 생성하면 대화방에 위와 같은 메시지가 뜹니다.",

    // charlie locale
    i10000: "조건 작성 도우미",
    i10001: "변환",
    i10002: "단일 조건 삭제 불가",
    i10003: "존재함",
    i10004: "존재하지 않음",
    i10005: "A는 B와 같다.",
    i10006: "A는 B와 같지 않다.",
    i10007: "A는 B보다 크다.",
    i10008: "A는 B보다 크거나 같다.",
    i10009: "A는 B보다 작다.",
    i10010: "A는 B보다 작거나 같다.",
    i10011: "응답 지연 메시지",
    i10012: "다음 중 랜덤, 기본값은 메시지 생성 중입니다",
    i10013: "응답 지연 메시지에 빈 문자열이 포함되었습니다.",
    i10014: "응답 지연 메시지 발송 시간(초)",
    i10015: "AI 챗봇 모드",
    i10016: "Kakao Item Card",
    i10017: "아이템 설정",
    i10018: "멤버 관리",
    i10019: "초대 수락",
    i10020: "거절",
    i10021: "시나리오 초대",
    i10022: "초대",
    i10023: "멤버 삭제",
    i10024: "멤버 초대",
    i10025: "멤버 수정",
    i10026: "소유자 변경",
    i10027: "시나리오 소유자를 변경하시겠습니까?",
    i10028: "변경",
    i10029: "변경 중 오류가 발생했습니다.",
    i10030: "시나리오 소유자가 변경되었습니다.",

    i0822: "배포",
    i0823: "다운로드",

    i200001: "LLM answer",
    i200002: "LLM 생성 요청 결과를 보여줍니다.",
    i200003: "LLM 답변 설정"
};
