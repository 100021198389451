import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./static/css/style.scss";
import "./static/css/basic.scss";
import "./static/css/icons.css";
import "./static/css/app.scss";
import cssVars from "css-vars-ponyfill";
import { IntlProvider } from "react-intl";
import locale from "./locale";
import localeStore from "./store/LocaleStore";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { configure } from "mobx";
import facebookSdkStore from "./store/FacebookSdkStore";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    DISABLE_BUGSNAG,
    FACEBOOK_APP_ID,
    THEME,
    DISABLE_INTEGRATION_FACEBOOK,
    DISABLE_INTEGRATION_INSTAGRAM,
    FACEBOOK_APP_SECRET,
    CM_CTX
} from "./variables";
import axios from "axios";

axios.defaults.withCredentials = true;


configure({
    useProxies: "never"
});

Bugsnag.start({
    onError: () => {
        return !DISABLE_BUGSNAG;
    },
    apiKey: "86de7374abe2270cf12d23864fc26427",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production"]
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    }
});

type Theme = "42maru" | "kia";
const theme = {
    "42maru": {
        "--margin-left-menu": "260px",
        "--background-main": "#030678",
        "--color-main": "#030678",
        "--color-main-gray": "#525252",
        "--color-sub-light-gray": "#E4E5E6",
        "--color-sub-medium-gray": "#C4C6C8",
        "--color-sub-text-gray": "#58595B",
        "--color-button-hover": "#3040ba",
        "--color-red": "#b50000",
        "--color-selected-item-light": "#bbc8fc",
        "--color-selected-item-dark": "#596387"
    },
    kia: {
        "--margin-left-menu": "260px",
        "--background-main": "#1F1F1F",
        "--color-main": "#BB162B",
        "--color-main-gray": "#7E8083",
        "--color-sub-light-gray": "#E4E5E6",
        "--color-sub-medium-gray": "#C4C6C8",
        "--color-sub-text-gray": "#58595B",
        "--color-button-hover": "#BB162B",
        "--color-red": "#BB162B",
        "--color-selected-item-light": "#E4E5E6",
        "--color-selected-item-dark": "#7E8083"
    }
};

cssVars({
    rootElement: document,
    variables: theme[(THEME as Theme) || "42maru"]
});

if (
    FACEBOOK_APP_ID &&
    FACEBOOK_APP_SECRET &&
    !(DISABLE_INTEGRATION_FACEBOOK && DISABLE_INTEGRATION_INSTAGRAM)
) {
    facebookSdkStore.initialize(FACEBOOK_APP_ID, FACEBOOK_APP_SECRET);
}

ReactDOM.render(
    <IntlProvider defaultLocale={"ko"} locale={localeStore.locale} messages={locale[localeStore.locale]}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter basename={CM_CTX || ''}>
                <App/>
            </BrowserRouter>
        </QueryClientProvider>
    </IntlProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
